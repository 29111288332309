import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import {base} from './base';
import ReactGA from 'react-ga';
import Analytics from 'analytics'
import hubSpotPlugin from '@analytics/hubspot'

let analytics;
if(process.env.REACT_APP_HUBSPOT_ID){
    analytics = Analytics({
        app: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        plugins: [
            hubSpotPlugin({
                portalId: process.env.REACT_APP_HUBSPOT_ID
            })
        ]
    })
}

if(process.env.NODE_ENV === "production"){
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production"){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
    if(analytics){
        analytics.page();
    }
}

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this)
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false
        }
    }

    componentDidMount() {
        this.getTenantVariables();
    }

    setCurrentUser() {
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    getTenantVariables(){
        let language = "english"
        let splitWindowLocation = window.location.pathname.split('/') || ""
        if(window.location.pathname.split('/').length > 2){
            language = splitWindowLocation[2]
        } else if(window.location.pathname.split('/').length > 1){
            language = splitWindowLocation[1]
        }
        this.loadLanguage(language)
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then(variables){
                document.title = variables.pageTitle || "Game";
                this.setState({
                    authenticated: false,
                    variables: variables,
                    loading: false,
                    language: language
                })
            }
        });
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    setPassedEmail(isSet=true){
      this.setState({
          passedEmail: isSet
      })
    }

    async loadLanguage(language){
        let languagesConstants;
        if(language){
            try {
                languagesConstants = await import("./constants/languages/"+language+".js");
            } catch(e) {
                language = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
                languagesConstants = await import("./constants/languages/"+language+".js");
            }
        } else {
            language = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
            languagesConstants = await import("./constants/languages/"+language+".js");
        }
        this.setState({
            languageConstants: languagesConstants
        })
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age;
        try {
            user_age = sessionStorage.getItem('verifiedAge')
        } catch (e) {
            user_age = false;
        }
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading backgroundImage={this.state.variables.backgroundImage} primaryColor={this.state.variables.primaryColor || "#000"}/>
            )
        }
        return (
          <div style={{ margin: "0 auto"}}>
            <BrowserRouter>
                <div className="main-content">
                    <div className="workspace">
                      <Switch>
                        <Route
                            strict
                            path="/login"
                            render={(props) => {
                                return <Login authenticated={this.state.authenticated} loadPage={() => logPageView()} stringConstants={this.state.languageConstants} variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} checkForAgeGate={this.checkForAgeGate} setCurrentUser={this.setCurrentUser} {...props} />
                            }}
                        />

                          <AuthenticatedRoute
                              exact
                              path="/"
                              authenticated={this.state.authenticated}
                              stringConstants={this.state.languageConstants}
                              variables={this.state.variables}
                              passedEmail={this.state.passedEmail}
                              loadPage={() => logPageView()}
                              checkForAgeGate={this.checkForAgeGate}
                              component={MainApp} />

                        {/*<Route*/}
                        {/*    path="/"*/}
                        {/*    render={(props) => {*/}
                        {/*        return <MainApp loadPage={() => logPageView()} stringConstants={this.state.languageConstants} variables={this.state.variables} passedEmail={this.state.passedEmail} checkForAgeGate={this.checkForAgeGate} {...props} />*/}
                        {/*    }}*/}
                        {/*/>*/}

                        <Route
                            strict
                            path="/age_gate"
                            render={(props) => {
                                return <AgeVerification authenticated={this.state.authenticated} loadPage={() => logPageView()} stringConstants={this.state.languageConstants} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                            }}
                        />

                        <Redirect to='/login'/>
                      </Switch>
                    </div>
                </div>
            </BrowserRouter>
              {/*{process.env.REACT_APP_FIREBASE_PROJECT_ID === "uhmsweeps1" &&*/}
              {/*    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/21078790.js"/>*/}
              {/*}*/}
          </div>
        )
    }
}

export default App;
